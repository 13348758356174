  <template>
<div class="mb-5">
    <Alert v-bind:message='message' type="alert-success" v-if="message" />
    <Alert v-bind:message='error' type="alert-danger" v-if="error" />
     <div class="row ">
        <div class="col">
          <button style="float:right" data-toggle="modal"
             data-target="#addGlobalAdmin" class="btn btn-danger my-2">
             <i class="fas fa-pen-alt"></i> Add Entity Admin
          </button>
        </div>
      </div>
      <div class="row">
          <div class="col">
          <div class="card border-left-danger">
              <div class="card-body">
              <div class="row" >
                  <div class="col">
                  <p class="my-5 text-center"></p>
                  </div>
              </div>
              <div class="row">
                  <div class="col">
                  <div class="card">
                      <div class="card-body">
                      <div class="table-responsive">
                          <table
                          class="table table-bordered"
                          id="dataTable"
                          width="100%"
                          cellspacing="0"
                          >
                          <thead>
                              <tr>
                              <th>Name</th>
                              <th>Email</th>
                              <th>Actions</th>
                              </tr>
                          </thead>
                          <tbody>
                              <tr v-for="(admin, key) in entityAdmins " :key="key">
                              <td>{{admin.name}}</td>
                              <td>{{admin.email}}</td>
                              <td>
                                  <button 
                                    v-if="!admin.suspended"
                                    @click="suspendEntityAdmin(admin.id,key)" 
                                    class="btn action btn-info mb-1 mr-3">
                                      suspend
                                  </button>
                                  <button 
                                    v-if="admin.suspended"
                                    @click="UnuspendEntityAdmin(admin.id,key)" 
                                    class="btn action btn-success">
                                      unsuspend
                                  </button>
                              </td>
                              </tr>
                          </tbody>
                          <tfoot>
                              <tr>
                              <th>Name</th>
                              <th>Email</th>
                              <th>Role</th>
                              </tr>
                          </tfoot>
                          </table>
                      </div>
                      </div>
                  </div>
                  </div>
              </div>
              </div>
          </div>
        </div>
    </div>
  <!-- Modal contents -->
  <div class="modal fade" id="addGlobalAdmin"
     tabindex="-1" aria-labelledby="add market" aria-hidden="true">
        <div class="modal-dialog">
          <div class="modal-content">
            <div class="modal-header">
              <h5 class="modal-title" id="exampleModalLabel">Add Entity Admin</h5>
              <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                <span aria-hidden="true">&times;</span>
              </button>
            </div>
            <div class="modal-body">
                <Alert v-bind:message='addsuccess' type="alert-success" v-if="addsuccess" />
                <Alert v-bind:message='adderror' type="alert-danger" v-if="adderror" />
                <form class="" @submit.prevent="createEntityAdmin">
                  <div class="form-group mb-3">
                      <label for="name" class="form-label">Name</label>
                      <input type="text" class="form-control" id="name" 
                      placeholder="eg, Mr. Kwaku menu" v-model="name" required>
                  </div>
                  <div class="form-group mb-3">
                      <label for="email" class="form-label">Email</label>
                      <input type="email" class="form-control" id="email" placeholder="me@example.com" v-model="email" required>
                  </div>  
                  <button style="float:right" type="submit" class="btn btn-danger my-2 btn-lg">
                    Create 
                      <RoundSpinner v-if="loading" />
                  </button>
              </form> 
            </div>
          </div>
        </div>
    </div>

</div>
</template>     
<script>
import Alert from '@/components/Alert'
import RoundSpinner from '@/components/RoundSpinner'
import { mapGetters } from 'vuex'
// import Table from '../admin/Table'

export default {
    name:'super-admin',
    components: {
        Alert:Alert,
        RoundSpinner:RoundSpinner,
        // Table:Table
    },
    data(){
        return {
            name:'',
            email:'',
            role:'',
            addsuccess:null,
            adderror:null,
            message:null,
            error:null,
            loading:false,
        }
    }
    ,
    methods:{
        createEntityAdmin(){
          this.loading = true
            const data = {  
                name:this.name,
                email:this.email,
                role:'Entity',
            }

            this.$store.dispatch('createEntityAdmin', data)
                .then(response => {
                   this.loading = false
                    this.addsuccess =  'Entity Admin created successfully'
                    this.role = ''
                    this.email = ''
                    this.name = ' '
                }).catch(err => {
                    if(err?.response?.data === undefined) {
                      this.loading = false
                      this.adderror = "Network Error."
                    }
                })
        },
        suspendEntityAdmin(id,key) {
            console.log('key', key, 'id', id)
            this.message = null
            this.error = null
            this.$store.dispatch('suspendEntityAdmin', {id:id, key:key})
                .then(response => {
                    console.log('response', response.data.admin)
                    this.message = 'Entity Admin Suspended.'
                }).catch(err => {
                    console.log('err', err)
                    this.error = 'Network Error'
                })
        },
        UnuspendEntityAdmin(id,key) {
            this.error = null
            this.message = null
            this.$store.dispatch('unSuspendEntityAdmin', {id:id, key:key})
                .then(response => {
                    console.log('response', response.data.admin)
                    this.message = 'Entity Admin unsuspended.'
                }).catch(err => {
                    console.log('err', err)
                    this.error = 'Network Error'
                })
        },
    },
    computed: {
        ...mapGetters(['entityAdmins'])
    },
    created() {
        this.$store.dispatch('fetchEntityAdmins')
    } 
    
}
</script>
<style scoped>
.nav-link{
    color: #ba2428;
}
a#pills-profile-tab.nav-link.active,a#pills-home-tab.nav-link.active{
    background-color:#ba2428;
    color:#fff;
}
.action{
    width:110px;

}
button, table , a{
    font-size: .9em;
  }
</style>